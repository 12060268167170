import dayjs from 'dayjs';
import apiClient from '../../../services/api';
import { Booking, DataItem } from './types';

interface Variables {
    ticketData: DataItem;
    status: string;
    ticketNumber?: string;
    ticketPrice?: string;
    refundable?: boolean;
    comment?: string;
    id?: number;
    requirementId?: number;
    currencyId?: number;
    type: 'FLIGHT' | 'TRAIN' | 'TAXI';
    custom_fields: [{ id: number; value: string | boolean }];
}

export const createBooking = async (variables: Variables) => {
    const { data: response } = await apiClient.post<Booking>('/api/bookings/', {
        ...variables.ticketData,
        ticket_number: variables.ticketNumber,
        price: variables.ticketPrice,
        is_refundable: Boolean(variables.refundable),
        comment: variables.comment || '',
        status: variables.status,
        requirement_id: variables.requirementId,
        currency_id: variables.currencyId,
        start_time_local: dayjs.utc(variables.ticketData?.start_time_local).format('YYYY-MM-DD HH:mm:ss'),
        end_time_local: dayjs.utc(variables.ticketData?.end_time_local).format('YYYY-MM-DD HH:mm:ss'),
        start_time_zulu: dayjs.utc(variables.ticketData?.start_time_zulu).format('YYYY-MM-DD HH:mm:ss'),
        end_time_zulu: dayjs.utc(variables.ticketData?.end_time_zulu).format('YYYY-MM-DD HH:mm:ss'),
        type: variables.type || '',
        custom_fields: variables.custom_fields,
    });

    return response;
};

export const updateBooking = async (variables: Variables) => {
    const { data: response } = await apiClient.put<Booking>(`/api/bookings/${variables.ticketData?.booking?.id}`, {
        ...variables.ticketData,
        ticket_number: variables.ticketNumber,
        price: variables.ticketPrice,
        is_refundable: Boolean(variables.refundable),
        comment: variables.comment || '',
        status: variables.status,
        requirement_id: variables.requirementId,
        currency_id: variables.currencyId,
        start_time_local: dayjs.utc(variables.ticketData?.start_time_local).format('YYYY-MM-DD HH:mm:ss'),
        end_time_local: dayjs.utc(variables.ticketData?.end_time_local).format('YYYY-MM-DD HH:mm:ss'),
        start_time_zulu: dayjs.utc(variables.ticketData?.start_time_zulu).format('YYYY-MM-DD HH:mm:ss'),
        end_time_zulu: dayjs.utc(variables.ticketData?.end_time_zulu).format('YYYY-MM-DD HH:mm:ss'),
        type: variables.type || '',
        custom_fields: variables.custom_fields,
    });

    return response;
};
