import { useQuery } from 'react-query';

import apiClient from '../../../services/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCustomFields = async (): Promise<any> => {
    const response = await apiClient.get('/api/custom-fields/');

    return response.data.data;
};

export function useCustomFields() {
    return useQuery(['use-custom-fields'], () => getCustomFields(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });
}
