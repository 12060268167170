import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import useLocalStorage from '../helpers/useLocalStorage';
import { login, logout } from '../services/auth';

export interface User {
    id: string;
    name: string;
    email: string;
}
export interface AuthenticatedUserContextValue {
    user: User | null;
    onLogin: (email: string, password: string) => Promise<User>;
    onLogout: () => Promise<void>;
}

export const AuthContext = createContext<AuthenticatedUserContextValue | null>(null);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [storedUser, setStoredUser] = useLocalStorage<User | null>('user', null);
    const [user, setUser] = useState<User | null>(storedUser);

    const handleLogin = async (email: string, password: string) => {
        const userCreds = await login(email, password);

        setUser(userCreds);
        setStoredUser(userCreds);

        return userCreds;
    };

    const handleLogout = async () => {
        try {
            await logout();
            setUser(null);
            setStoredUser(null);
            return;
        } catch (error) {
            console.log(error);
        }
    };

    const value = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthenticatedUserContextValue => {
    const authenticatedUserContext = useContext(AuthContext);

    if (authenticatedUserContext === null) {
        throw new Error('AuthenticatedUserContext should not be null');
    }

    return authenticatedUserContext;
};
