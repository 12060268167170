import { Heading, PrimaryButton } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, PropsWithChildren } from 'react';

const useStyles = makeStyles(theme => ({
    refreshButton: { margin: theme.spacing(2, 0) },
    container: { margin: theme.spacing(2) },
}));

const Refresh: FunctionComponent<PropsWithChildren> = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Heading level={2}>Unexpected error</Heading>
            <div>Sorry, something went wrong on our end. Please refresh the page or contact our 24/7 support team</div>
            <PrimaryButton className={classes.refreshButton} onClick={() => window.location.reload()}>
                Refresh
            </PrimaryButton>
        </div>
    );
};

export default Refresh;
