import { AppBar, Grid, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import useTopBarStyles from './TopBar.styles';

export interface TopBarProps {
    closed: boolean;
}

const TopBar: FC<PropsWithChildren<TopBarProps>> = ({ closed, children }) => {
    const classes = useTopBarStyles();

    return (
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: !closed })}>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    {children}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
