import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Activities';

export enum ActivitiesTabs {
    All,
    ToBook,
    Requested,
    ToCancel,
    Processed,
}

export const allPage: PageDefinition = {
    path: '/activities/:bucketID/all',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning activities' }}>
                <Content tab={ActivitiesTabs.All} />
            </Page>
        );
    },
};

export const toBookPage: PageDefinition = {
    path: '/activities/:bucketID/to-book',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning activities' }}>
                <Content tab={ActivitiesTabs.ToBook} />
            </Page>
        );
    },
};

export const toCancelPage: PageDefinition = {
    path: '/activities/:bucketID/to-cancel',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning activities' }}>
                <Content tab={ActivitiesTabs.ToCancel} />
            </Page>
        );
    },
};

export const processedPage: PageDefinition = {
    path: '/activities/:bucketID/processed',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning activities' }}>
                <Content tab={ActivitiesTabs.Processed} />
            </Page>
        );
    },
};

export const requestedPage: PageDefinition = {
    path: '/activities/:bucketID/requested',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning activities' }}>
                <Content tab={ActivitiesTabs.Requested} />
            </Page>
        );
    },
};
