import { Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import useNavigationBarStyles from './NavigationBar.styles';
import SideBarLogo from './SideBarLogo';

const NavigationBarFooter: FC<PropsWithChildren<{ closed: boolean }>> = ({ closed }) => {
    const classes = useNavigationBarStyles();

    return (
        <Grid item className={classes.companyInformationContainer}>
            <SideBarLogo closed={closed} />
        </Grid>
    );
};

export default NavigationBarFooter;
