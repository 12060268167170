import { createContext, useContext } from 'react';

export const supportedLocales = ['en-GB'] as const;

export type SupportedLocale = (typeof supportedLocales)[number];

export const isSupportedLocale = (value: string): value is SupportedLocale => {
    const locales = supportedLocales as readonly string[];

    return locales.includes(value);
};

export interface LocaleContextProps {
    locale: SupportedLocale;
}

export const LocaleContext = createContext<LocaleContextProps>({ locale: 'en-GB' });

export const useLocaleContext = (): LocaleContextProps => {
    const localeContext = useContext(LocaleContext);

    if (localeContext === null) {
        throw new Error('LocaleContext should not be null');
    }

    return localeContext;
};
