import axios from 'axios';
import getEnv from '../helpers/getEnv';

const apiClient = axios.create({
    baseURL: getEnv('API_URL'),
    withCredentials: true,
});

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            window.location.href = '/sign-in';
        } else {
            return Promise.reject(error);
        }
    }
);

export default apiClient;
