/* eslint-disable max-params */
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from 'react-query';

import { ActivitiesTabs } from '..';
import useDebounce from '../../../helpers/useDebounce';
import apiClient from '../../../services/api';
import { APIResponse } from './types';

const getBookings = async (
    search: string,
    page: number,
    perPage: number,
    bucketID: string,
    dateRange: [Dayjs, Dayjs] | null,
    order_by: string,
    order_direction: string,
    endpoint: string
) => {
    const startDate = dateRange && dayjs(dateRange[0]).isValid() ? dayjs(dateRange[0]).format('YYYY-MM-DD HH:mm:ss') : '';

    const endDate =
        dateRange && dayjs(dateRange[1]).isValid() ? dayjs(dateRange[1]).clone().endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';

    const response = await apiClient.get<APIResponse>(
        `/api/${endpoint}/?filters[bucket]=${bucketID === 'all' ? '' : bucketID}&search=${search}&page=${
            page + 1
        }&perPage=${perPage}&filters[startDate]=${startDate}&filters[endDate]=${endDate}&orderBy=${order_by ?? ''}&orderDirection=${
            order_direction || 'ASC'
        }`
    );

    return response.data;
};

export function useBookings(
    activeTab: number,
    search: string,
    page: number,
    perPage: number,
    bucketID: string,
    dateRange: [Dayjs, Dayjs] | null,
    order_by: string,
    order_direction: string,
    endpoint: string,
    tabToEnable: ActivitiesTabs
) {
    const debouncedSearchQuery = useDebounce(search, 500);

    return useQuery(
        [endpoint, debouncedSearchQuery, bucketID, page, dateRange, order_by, order_direction || 'ASC'],
        () => getBookings(debouncedSearchQuery, page, perPage, bucketID, dateRange, order_by, order_direction, endpoint),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: activeTab === tabToEnable,
        }
    );
}
