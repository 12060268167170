import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton } from '@mui/material';
import React from 'react';

import { Severity, useNotificationContext } from '../context/NotificationContext';

interface CopyToClipboardButtonProps {
    text: string;
}

const CopyToClipboardButton = ({ text }: CopyToClipboardButtonProps) => {
    const { showNotification } = useNotificationContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        showNotification('Copied to clipboard!', Severity.Info);
        return navigator.clipboard.writeText(text);
    };

    return (
        <IconButton onClick={handleClick} style={{ padding: '0.5rem' }}>
            <ContentCopyOutlinedIcon
                style={{
                    fontSize: '1.25rem',
                    color: '#788B9B',
                    marginRight: '0',
                }}
            />
        </IconButton>
    );
};

export default CopyToClipboardButton;
