import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chip, Drawer, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import React, { FunctionComponent, ReactNode } from 'react';
import useNavigationBarStyles from './NavigationBar.styles';

function getValue<T>(getter: () => T): T {
    return getter();
}

export interface NavigationBarProps {
    closed: boolean;
    onMenuClose: () => void;
    onMenuOpen: () => void;
    footer?: ReactNode;

    /**
     * @deprecated Use `environmentBadge` instead.
     */
    isDevEnvironment?: boolean;

    /**
     * Text to show as a badge to indicate the current environment.
     * Should not be used on production.
     */
    environmentBadge?: string;
}

const NavigationBar: FunctionComponent<React.PropsWithChildren<NavigationBarProps>> = ({
    closed,
    onMenuOpen: openMenu,
    onMenuClose: closeMenu,
    isDevEnvironment,
    environmentBadge,
    children,
    footer,
}) => {
    const classes = useNavigationBarStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const closeOnMobile = (): void => {
        if (mobileView) {
            closeMenu();
        }
    };

    return (
        <Drawer
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: !closed,
                [classes.drawerClose]: closed,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: !closed,
                    [classes.drawerClose]: closed,
                }),
            }}
            variant="permanent"
            anchor="left"
            open={!closed}
        >
            <div className={classes.toolbar}>
                {getValue(() => {
                    const label = getValue((): string | null => {
                        if (environmentBadge) {
                            return environmentBadge;
                        }

                        if (isDevEnvironment) {
                            return 'Development';
                        }

                        return null;
                    });

                    return label ? <Chip label={label} /> : null;
                })}

                {closed ? (
                    <IconButton onClick={openMenu} title="menu-open" aria-label={t('buttons.openMenu')} size="large">
                        <ChevronRightIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={closeMenu} title="menu-close" aria-label={t('buttons.closeMenu')} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                )}
            </div>

            <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                    <div onClick={() => closeOnMobile()}>{children}</div>
                </Grid>

                {footer}
            </Grid>
        </Drawer>
    );
};

export default NavigationBar;
