import { MenuButton, MenuButtonItem, userButtonIcon } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthenticatedUserContext';

const useStyles = makeStyles(theme => ({
    button: { padding: '.5rem 1rem !important' },
    icon: { [theme.breakpoints.up('sm')]: { margin: '0 0 0 .5em' } },
}));

const UserMenuButton: FC<
    PropsWithChildren<{
        className?: string;
    }>
> = ({ className }) => {
    const classes = useStyles();
    const { user, onLogout } = useAuth();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [signingOut, setSigningOut] = useState(false);

    const signOutFailed = (): void => {
        setSigningOut(false);
        setMenuOpen(false);
    };

    const signOut = async (): Promise<void> => {
        setSigningOut(true);

        try {
            await onLogout();
            navigate('/sign-in');
        } catch (error) {
            signOutFailed();
        }
    };

    return (
        <span className={className}>
            <MenuButton
                className={classes.button}
                open={menuOpen}
                onClick={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                menuItems={[
                    <MenuButtonItem key="sign-out" loading={signingOut} onClick={signOut} dangerous>
                        Sign out
                    </MenuButtonItem>,
                ]}
            >
                {user?.name ?? 'Username'}
                <img src={userButtonIcon} alt="User icon" className={classes.icon} />
            </MenuButton>
        </span>
    );
};

export default UserMenuButton;
