export default function getEnv(key: string): string {
    const envFileKey = `REACT_APP_${key}`;

    const value = process.env[envFileKey];

    if (typeof value !== 'string') {
        throw new Error(`Environment variable "${envFileKey}" does not exist`);
    }

    return value;
}
