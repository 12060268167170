import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import {
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridGroupNode,
} from '@mui/x-data-grid-pro';

const CustomGroupingCell: React.FC<GridRenderCellParams> = props => {
    const { id, field, formattedValue, rowNode } = props;

    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);

    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    if (rowNode.type !== 'group' || rowNode.depth !== 0) {
        return null;
    }

    const groupNode = rowNode as GridGroupNode;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        apiRef.current.setRowChildrenExpansion(id, !groupNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const value = formattedValue || groupNode.groupingKey;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50px !important' }}>
            {filteredDescendantCount > 0 && (
                <IconButton size="small" onClick={handleClick}>
                    {groupNode.childrenExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                </IconButton>
            )}
            <span>{value}</span>
        </Box>
    );
};

export default CustomGroupingCell;
