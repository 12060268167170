import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppBar, Grid, IconButton, Slide, Toolbar, useScrollTrigger } from '@mui/material';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import useTopBarStyles from './TopBar.styles';
import useTopBarMobileStyles from './TopBarMobile.styles';

export interface MobileTopBarProps {
    menuClosed: boolean;
    onMenuOpen: () => void;
}

const TopBarMobile: FunctionComponent<React.PropsWithChildren<MobileTopBarProps>> = ({ menuClosed, onMenuOpen, children }) => {
    const trigger = useScrollTrigger();
    const topBarStyles = useTopBarStyles();
    const classes = useTopBarMobileStyles();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            <AppBar position="fixed" className={clsx(topBarStyles.appBar, { [topBarStyles.appBarShift]: !menuClosed })}>
                <Toolbar>
                    <IconButton
                        color="default"
                        aria-label="open drawer"
                        onClick={() => onMenuOpen()}
                        data-testid="open-menu-button"
                        edge="start"
                        className={clsx(classes.menuButton, { [classes.hide]: !menuClosed })}
                        size="large"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        {children}
                    </Grid>
                </Toolbar>
            </AppBar>
        </Slide>
    );
};

export default TopBarMobile;
