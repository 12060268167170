import amplitude from 'amplitude-js';

export const initAmplitude = () => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API ?? '');
};

export const setAmplitudeUserId = (userId: string) => {
    amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: object) => {
    amplitude.getInstance().setUserProperties(properties);
};

export const logAmplitudeEvent = (event: string, eventProperties = {}) => {
    amplitude.getInstance().logEvent(event, eventProperties);
};
