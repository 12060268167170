import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useTopBarMobileStyles = makeStyles(() =>
    createStyles({
        hide: { display: 'none' },
        menuButton: { marginRight: 0 },
    })
);

export default useTopBarMobileStyles;
