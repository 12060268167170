import { FC, PropsWithChildren } from 'react';
import { matchPath, Params } from 'react-router-dom';

import { allPage, toBookPage, toCancelPage, processedPage, requestedPage } from './pages/Activities';

export interface BreadcrumbHeadNode {
    name: string | JSX.Element;
    previous?: BreadcrumbNode;
}

export interface BreadcrumbNode {
    name: string | JSX.Element;
    path: string;
    previous?: BreadcrumbNode;
}

export function getParams(path: string): Params {
    const match = matchPath({ path }, window.location.pathname);

    if (!match) {
        throw new Error('Not a match');
    }

    return match.params;
}

export interface PageDefinition {
    path: string;
    Component: FC<PropsWithChildren<{ getParam: (key: string) => string }>>;
}

const pages: PageDefinition[] = [allPage, toBookPage, requestedPage, toCancelPage, processedPage];

export default function createRoutes(): Array<{
    path: string;
    Component: FC<PropsWithChildren>;
}> {
    return pages.map(({ path, Component }) => ({
        path,
        Component: (): ReturnType<typeof Component> =>
            Component({
                getParam(handle) {
                    const paramValue = getParams(path)[handle];

                    if (typeof paramValue !== 'string') {
                        throw new Error(`Path does not contain parameter "${handle}"`);
                    }

                    return paramValue;
                },
            }),
    }));
}
