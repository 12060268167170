import { FullScreenLoader } from '@get-e/react-components';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { setAmplitudeUserId, setAmplitudeUserProperties } from './amplitude/amplitude';
import { useAuth } from './context/AuthenticatedUserContext';
import createRoutes from './createRoutes';
import Page from './layouts/Page';
import ForgotPassword from './pages/ForgotPassword';
import SignIn from './pages/SignIn';

const InitialRoute: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/sign-in');
    }, [navigate]);

    return <FullScreenLoader />;
};

const ProtectedRoute: FC<{
    children?: ReactElement;
    redirectPath?: string;
    user?: unknown;
}> = ({ user, redirectPath = '/', children }) => {
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

const PortalRoutes: FC = () => {
    const { user } = useAuth();

    const routes = useMemo(() => {
        const routesConfigs = createRoutes();

        return routesConfigs.map(({ path, Component }) => <Route path={path} element={<Component />} key={path} />);
    }, []);

    setAmplitudeUserId(user?.id?.toString() ?? '');

    const userProperties = {
        'User id': user?.id,
        Name: user?.name,
        Email: user?.email,
    };

    setAmplitudeUserProperties(userProperties);

    return (
        <Routes>
            <Route index element={<InitialRoute />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route element={<ProtectedRoute user={user} />}>{routes}</Route>

            <Route path="*" element={<Page breadcrumbs={{ name: 'Not found' }}>Page not found</Page>} />
        </Routes>
    );
};

export default PortalRoutes;
