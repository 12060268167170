import { createContext, useContext, useState, ReactNode } from 'react';

export interface Bucket {
    code: string;
    id: number;
    name: string;
    is_local_time: boolean;
    type: 'FULL' | 'PARTIAL';
    is_grouped: boolean;
}

export interface CurrentBucketContextValue {
    bucket: Bucket | null;
    setCurrentBucket: (b: Bucket) => void;
}

export const CurrentBucketContext = createContext<CurrentBucketContextValue | null>(null);

export const CurrentBucketProvider = ({ children }: { children: ReactNode }) => {
    const [bucket, setCurrentBucket] = useState<Bucket | null>(null);

    return <CurrentBucketContext.Provider value={{ bucket, setCurrentBucket }}>{children}</CurrentBucketContext.Provider>;
};

export const useCurrentBucket = (): CurrentBucketContextValue => {
    const context = useContext(CurrentBucketContext);

    if (!context) {
        throw new Error('useCurrentBucket must be used within a CurrentBucketProvider');
    }

    return context;
};
