import dayjs, { Dayjs } from 'dayjs';

export const parseDateRange = (storedDateRange: string | null): [Dayjs | null, Dayjs | null] => {
    if (!storedDateRange) {
        return [null, null];
    }

    try {
        const parsedRange = JSON.parse(storedDateRange);

        return [parsedRange[0] ? dayjs(parsedRange[0]) : null, parsedRange[1] ? dayjs(parsedRange[1]) : null];
    } catch (e) {
        return [null, null];
    }
};
