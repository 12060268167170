import { useQuery } from 'react-query';

import apiClient from '../../../services/api';
import { Currency } from './types';

export const getCurrencies = async () => {
    const response = await apiClient.get<{ data: Currency[] }>('/api/currencies/');

    return response.data.data;
};

export function useCurrencies() {
    return useQuery(['use-currencies'], () => getCurrencies(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });
}
