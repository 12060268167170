import { SecondaryButton } from '@get-e/react-components';
import { Check, Close } from '@mui/icons-material';
import { GridColDef, GridGroupingColDefOverride, GridRenderCellParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';

import CustomGroupingCell from '../../../components/CustomGroupingCell';
import { DataItem } from '../api/types';

export const fieldSortMapping: Record<string, string> = {
    start_time_local: 'DATE_LOCAL',
    flight_number: 'FLIGHT_NUMBER',
    employee_name: 'NAME',
    crew_code: 'CREW_CODE',
    bucket_code: 'BUCKET_CODE',
};

export const groupingColDef: GridGroupingColDefOverride = {
    headerName: '',
    minWidth: 358,
    renderCell: params => {
        if (params.rowNode.depth !== 0) {
            return null;
        }

        return <CustomGroupingCell {...params} />;
    },
};

export const getActivitiesColumns = (isLocalTime: boolean): Array<GridColDef<DataItem>> => {
    return [
        {
            field: 'start_time_local',
            headerName: 'Date',
            minWidth: 130,
            flex: 1,
            sortable: true,
            valueGetter: (value, row) => {
                const timeSource = isLocalTime ? row.start_time_local : row.start_time_zulu;

                return timeSource ? dayjs.utc(timeSource).format('DD MMM YYYY') : '';
            },
        },
        {
            field: 'route',
            headerName: 'Route',
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return '';
                }

                const timeSourceStart = isLocalTime ? params.row.start_time_local : params.row.start_time_zulu;

                const timeSourceEnd = isLocalTime ? params.row.end_time_local : params.row.end_time_zulu;

                return (
                    <div className="dataGridRoute">
                        {params?.row?.departure_identifier}-{params?.row?.arrival_identifier}
                        <span className="dataGridTime">
                            {dayjs.utc(timeSourceStart).format('HH:mm')} - {dayjs.utc(timeSourceEnd).format('HH:mm')}{' '}
                            {isLocalTime ? 'LT' : 'UTC'}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'flight_number',
            headerName: 'Flight number',
            minWidth: 80,
            flex: 2,
            sortable: true,
            valueGetter: (value, row) => {
                if (Object.keys(row).length === 0) {
                    return '';
                }

                const separator = row?.type !== 'FLIGHT' ? ' ' : '';

                return `${row?.provider || ''}${separator}${row?.code || ''}`;
            },
        },
        {
            field: 'employee_name',
            headerName: 'Name',
            minWidth: 200,
            flex: 2,
            sortable: true,
            valueGetter: (value, row) =>
                Object.keys(row).length === 0 ? '' : `${row?.employee?.first_name} ${row?.employee?.last_name}`,
        },
        {
            field: 'crew_code',
            headerName: 'Crew code',
            minWidth: 60,
            flex: 2,
            sortable: true,
            valueGetter: (value, row) => (Object.keys(row).length === 0 ? '' : `${row?.employee?.number}`),
        },
        {
            field: 'bucket_code',
            headerName: 'Bucket code',
            minWidth: 100,
            flex: 2,
            sortable: true,
            valueGetter: (value, row) => (Object.keys(row).length === 0 ? '' : `${row?.bucket_code || ''}`),
        },
        {
            field: 'duty_designations',
            headerName: 'Duty Des.',
            minWidth: 80,
            flex: 2,
            sortable: false,
        },
        {
            field: 'ticket_number',
            headerName: 'Ticket #',
            minWidth: 100,
            flex: 1,
            sortable: false,
            valueGetter: (value, row) => (Object.keys(row).length === 0 ? '' : row?.booking?.ticket_number),
        },
        {
            field: 'is_refundable',
            headerName: 'Refund.',
            minWidth: 65,
            flex: 1,
            sortable: false,
            renderCell: params => {
                if (!params.row.booking) {
                    return '';
                }

                return params?.row?.booking?.is_refundable ? (
                    <Check sx={{ color: 'green', marginTop: '12px' }} />
                ) : (
                    <Close sx={{ color: 'red', marginTop: '12px' }} />
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 165,
            flex: 1,
            sortable: false,
            renderCell: params =>
                params.rowNode.type === 'group' ? (
                    ''
                ) : (
                    <SecondaryButton
                        small
                        onClick={() => console.log('')}
                        className={`dataGridStatus${params?.row?.booking?.status || 'TO_BOOK'}`}
                    >
                        {params?.row?.booking?.status.replace('_', ' ') || 'BOOK'}
                    </SecondaryButton>
                ),
        },
    ];
};
